import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import JaegerLeCoultureCategoryBtns from '../../components/preowned/JaegerLeCoultureCategoryBtns'

// markup
const Usedjaegerlecoultrepolaris = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedJaegerLeCoulturePolaris {
        products: allStrapiProduct(
          filter: { brand: { eq: "Jaeger LeCoulture" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Jaeger LeCoultre Polaris Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/jaeger-lecoultre/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Jaeger LeCoultre Polaris with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
              <StaticImage
                src="../../images/preowned/preowned-certifeid-used-jaeger-lecoulture-reverso-watches-banner-1920x1080.jpg"
                alt="Pre-Owned Certified Used Jaeger LeCoulture Watches Header"
                aria-label="Used Jaeger LeCoultre Polaris Watches"
              />
            </a>
            {/* NO PHOTOS OF THE POLARIS WATCH AVAILABLE VIA GRAY AND SONS */}
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl">
              Buy Sell Trade Consign Repair Restore Jaeger LeCoultre Polaris Watches{' '}
            </h1>
            <br />

            <h2 className="text justify mx-auto text-2xl">Jaeger LeCoultre Polaris History</h2>
            <p className="tracking-wide">
              When thinking of historical{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/"> JLC </a>
              watches, of course, the first that comes to mind is the{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">
                {' '}
                Jaeger LeCoultre Reverso collection.
              </a>
              <br />
              &nbsp;However, when looking into the Jaeger LeCoultre models that have transfigured
              the watch world and their utility, the Jaeger LeCoultre Polaris is it. Being the first
              of its kind, the{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">JLC</a> Polaris
              revolutionized the diving watch industry by making the alarm function a must-have
              watch attribute for avid divers. This icon of a watch made it so that divers only
              began purchasing watches with alarm functions that worked underwater. How and why,
              many may ask. Well, in the 60’s,
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">JLC</a> concentrated
              their watchmakers’ focus on “the voice of memory” or “Memovox” in latin. After a few
              years of perfecting this idea, the Jaeger LeCoultre Memovox Polaris was released in
              1968, impacting the world of diver watches forever. The attributes of this timepiece
              that made this alarm function a possibility not only robustified the case but favored
              the dial as it was much easier to read the time. The 42mm timepiece carries a triple
              caseback system and a watertight crown making it absolutely water resistant. The alarm
              functions was extremely resonant, this is the ideal diving watch. What made this watch
              unlike any other, was Jaeger LeCoultre’s craftsmanship and attention to detail when
              perfecting the triple case back. This watchmaking technique made history. The ability
              for a watch alarm to sound significantly loud under water, thus reminding divers when
              it’s time to return to the surface, was way ahead of its time. This groundbreaking
              innovation was made possible by shaping the caseback so that it was slightly inclined,
              preventing the alarm sounds from being stifled when worn under a diving suit. The name
              Polaris for this Jaeger LeCoultre model was inspired by the explorations and journeys
              in expeditions to the poles, which many thought was impossible. Like this watch model,
              these great adventures made the impossible possible. When the Jaeger LeCoultre Memovox
              Polaris models celebrated their 50 years of fabrication in 2018, they decided to
              dedicate an entire collection solely for the Memovox Polaris model. Lionel Favre, one
              of the talented watchmakers responsible for creating the masterpiece that is the
              Polaris Memovox, explained how this watch combined appeal with utility to create the
              perfect everyday watch with multiple functionality. Favre stated how his idea was to
              create a “montre a vivre” which translates to “watch to live”, he explained how the
              “complications of the watch are useful, simple and efficient and it’s alarm function
              is quite practical for arranging the time of a meeting!”. The Jaeger LeCoultre Memovox
              Polaris is and will continue to be one of the most revolutionary timepieces to ever be
              created.
            </p>

            <h2 className="text-3xl">
              Buying and Selling Pre-Owned Jaeger LeCoultre Polaris Watches{' '}
            </h2>
            <br />
            <p>
              Since its release,the{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">JLC</a> Polaris has
              been a huge success. These elegant timepieces draw the attention of any watch
              enthusiast that comes near them. The Jaeger LeCoultre Polaris collection is one of
              Jaeger LeCoustre’s universal pieces appropriate for almost any setting. Under the
              umbrella of Jaeger LeCoultre Polaris watches fall the following references:
            </p>
            <h2 className="text justify mx-auto text-2xl">
              Popular Pre-Owned Used Jaeger LeCoultre Polaris Watch References and Models
            </h2>

            <p>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">
                  Pre-Owned Jaeger LeCoultre Polaris Automatic references: 9008180, Q9008170,
                  9008471
                </a>{' '}
              </li>

              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">
                  Pre-Owned Jaeger LeCoultre Polaris Mariner references: Q9068180, 9038180, 9068180
                </a>{' '}
              </li>

              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">
                  Pre-Owned Jaeger LeCoultre Polaris Chronograph references: Q9022450, 9028170,
                  Q905T480, Q905T471
                </a>{' '}
              </li>

              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/">
                  Pre-Owned Jaeger LeCoultre Polaris Date references: 9068670, Q9008471, 842.8.37
                </a>{' '}
              </li>
            </p>

            <h2>
              {' '}
              Since 1980, <a href="/">Gray and Sons</a> Jewelers has been catering to clients all
              around the country with all their luxury needs. All of the services we provide are
              done in our very own store, here, in the heart of Miami, Florida. We are the top watch
              store in all of South Florida specializing in buying pre-owned Jaeger LeCoultre
              watches, Selling used Jaeger LeCoultre watches, repairing second-hand Jaeger LeCoultre
              watches, and trading genuine Jaeger LeCoultre timepieces.
            </h2>

            <p>
              {' '}
              Gray and Sons Jewelers is the #1 all-stop shop for all Jaeger LeCoultre watch needs.
              Our store is located in South Florida in the city of Surfside across the street from
              the famous Bal Harbour Shops. Aside from buying Jaeger LeCoultre Watches and selling
              genuine pre-owned Jaeger LeCoultre watches, Gray and Sons is also a certified repair
              center for all Swiss-made wristwatches. We have six master-trained watchmakers
              in-house with over 150 years of combined experience to repair Jaeger LeCoultre
              watches, including the reparation of a Jaeger LeCoultre Polaris. We take in repairs
              for swiss-made watches daily! Those looking to “get cash for my used Jaeger LeCoultre
              watch” or “sell my pre-owned Jaeger LeCoultre watch today” should visit
              <a href="/">www.grayandsons.com</a>
              or
              <a href="http://sellusyourjewelry.com/"></a> www.sellusyourjewelry.com to request a
              free watch quote to sell or trade-in for one of ours!
              <br />
              We have a brick-and-mortar store located in the heart of{' '}
              <a href="/sell-your-jewelry-miami-beach">Miami, Fl </a> near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives
              Viktoria and Rich who are specialists in buying and selling Jaeger LeCoultre Polaris
              watches. Our LIVE CHAT feature allows clients all around the nation to make all
              inquiries about Jaeger LeCoultre watches and possibly work out a deal in real-time.
              Gray and Sons Jewelers is the best and most reliable place to buy used pre-owned
              Jaeger LeCoultre watches. Our master-trained watchmakers assure every{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/"> Jaeger LeCoultre</a>{' '}
              watches in our inventory is certified pre-owned and serviced ready for wear with a
              1-year warranty covering the movement.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
          <button>Shop Jaeger LeCoultre Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Jaeger LeCoultre Polaris Watches:</h2>

        <iframe
          className="content-center"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1250"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <JaegerLeCoultureCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/Ih7LroA6z4AA'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Usedjaegerlecoultrepolaris
